import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import Attribution from "@/components/common/Attribution";
import CarmotThumbnail from "@/components/common/CarmotThumbnail";
import { Heading } from "@/components/stream/ArticleCategoryCard";
import { ImageTags } from "@/lib/contentGateway/util";

interface ThreeFeaturedCardProps {
  article: CarmotData;
  dataYlk?: YlkData;
}

const ThreeFeaturedCard: FC<ThreeFeaturedCardProps> = ({
  article,
  dataYlk = {},
}) => {
  return (
    <article className="group relative grid grid-cols-1 gap-3 overflow-hidden rounded-md border border-[#eceff2] bg-white sm:gap-3 md:grid-cols-1 md:gap-3">
      <div className="relative shrink-0">
        <CarmotThumbnail
          alt={article?.title!}
          className="relative size-full object-cover"
          thumbnail={article?.thumbnail!}
          size={ImageTags["16x9_MD"]}
        />
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-1.5 px-3 pb-4">
        <Heading
          title={article.title}
          canonicalUrl={
            article.clickThroughUrl?.url
              ? article.clickThroughUrl
              : article.canonicalUrl
          }
          className="headline-text-18px md:headline-text-17px line-clamp-3 sm:line-clamp-4 md:line-clamp-6 lg:line-clamp-3"
          dataYlk={dataYlk}
        />
        <Attribution author={article?.author!} />
      </div>
    </article>
  );
};

export default ThreeFeaturedCard;
