"use client";

import { type CarmotData, type MsbePagination } from "@yahoo-commerce/api";
import { type I13NSec, type YlkData } from "@yahoo-commerce/i13n";
import { NativeAd } from "@yahoo-commerce/monetization";
import { Pagination } from "@yahoo-commerce/pagination";
import {
  NumPaginatedItemsByModule,
  type PageContext,
} from "@yahoo-commerce/util";
import { useRef, type FC } from "react";
import { useIntl } from "react-intl";
import {
  type RightRailAdLocation,
  RightRailAds,
} from "@/components/ads/RightRailAds";
import StackedCard from "@/components/expModules/ArticlesStackedListNPagination/StackedCard";
import Section from "@/components/stream/layout/Section";
import { type PageModule } from "@/types/Experience";

interface Props {
  dataYlk?: YlkData;
  id: string;
  pageContext: PageContext;
  module: PageModule;
  articles: CarmotData[];
  pagination: MsbePagination | null | undefined;
  placement?: "main" | "ros";
}

const SEC: I13NSec = "stacked-article-list";

const Grid: FC<Props> = ({
  id,
  dataYlk,
  module,
  pageContext,
  articles,
  pagination,
  placement = "ros",
}) => {
  const { moduleTitle, moduleSubTitle } = module;
  const enableTaboola = module.moduleEnableTaboola ?? false;
  const sectionHeading = moduleTitle ?? "";
  const total = pagination?.total ?? 0;
  const intl = useIntl();

  const contentRef = useRef<HTMLUListElement>(null);
  const AD_LOCATIONS: RightRailAdLocation[] = [{ location: "mid_right" }];

  return (
    <Section
      key={id}
      className="grid grow"
      heading={sectionHeading}
      subtitle={moduleSubTitle}
      i13nSec={SEC}
      dataYlk={{}}
    >
      <div className="lg:grid lg:grid-cols-3 lg:gap-6">
        <ul
          className="flex flex-col gap-4 lg:col-span-2 lg:gap-6"
          ref={contentRef}
        >
          {articles.map((article, idx) => (
            <li key={article.uuid}>
              <StackedCard
                article={article}
                dataYlk={{
                  cpos: idx + 1,
                  elm: "hdln",
                  slk: article?.title?.replace(/[^\w\s-]/g, ""),
                  ...dataYlk,
                }}
                showSection={
                  !!module.moduleSiteSectionArticleLabel &&
                  !!article.siteSectionItem?.sectionName
                }
              ></StackedCard>

              {enableTaboola && (idx + 1) % 4 === 0 && (
                <div
                  id={`taboola-stream-${(idx + 1) / 4}`}
                  className="relative mt-4 flex min-h-[140px] sm:min-h-[152px] lg:mt-6"
                >
                  <div className="absolute left-0 top-0 flex min-h-[140px] w-full items-center justify-center text-xs uppercase sm:min-h-[152px]">
                    {intl.formatMessage({ id: "monetization.AD_PLACEHOLDER" })}
                  </div>
                  <NativeAd
                    className="relative inset-0 w-full"
                    mode="stream-d-rocket-v2"
                    placement={`taboola-stream-rocket-${(idx + 1) / 4}`}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
        <RightRailAds
          allowedAdSizes={[{ height: 250, width: 300 }]}
          className="justify-self-center"
          contentRef={contentRef}
          loading="lazy"
          locations={AD_LOCATIONS}
          maxAdCount={3}
          minScreenSize="lg"
          placement={placement}
        />
      </div>
      <Pagination
        pageContext={pageContext}
        dataYlk={{
          elm: "slide - dot",
        }}
        totalItems={total}
        itemsPerPage={NumPaginatedItemsByModule.LIST}
      />
    </Section>
  );
};

export default Grid;
