"use client";

import {
  type AdLoadingMode,
  buildResponsiveAdProps,
  ResponsiveAd,
} from "@yahoo-commerce/monetization";
import { SCREEN_WIDTH } from "@yahoo-commerce/util";
import classNames from "classnames";
import { memo, type FC } from "react";
import { useRequestContext } from "@/lib/request/client";

type Props = {
  backgroundColor?: string;
  loading?: AdLoadingMode;
  location?: string;
  locationNumber?: number;
  placement?: string;
};

const configs = buildResponsiveAdProps([
  {
    device: "mw",
    screenWidth: { to: SCREEN_WIDTH.md }, // up to (but not including) "md"
    sizes: [{ height: 250, width: 300 }],
  },
  {
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.md },
    sizes: [
      { height: 90, width: 728 },
      { height: 250, width: 970 },
    ],
  },
]);

// this component is memoized to prevent unnecessary rerenders of the underlying ad
export const MidCenterAd: FC<Props> = memo(
  ({
    backgroundColor = "bg-grey-hair",
    loading,
    location = "mid_center",
    locationNumber,
    placement = "ros",
  }) => {
    const { site } = useRequestContext();
    return (
      <section
        className={classNames(
          backgroundColor,
          "flex items-center justify-center md:min-h-[250px]",
        )}
        id={`mid-center-ad-${locationNumber}`}
      >
        <div className="flex items-center justify-center">
          <ResponsiveAd
            className="items-center justify-center"
            configs={configs}
            loading={loading}
            location={location}
            locationNumber={locationNumber}
            pageRegion="index"
            placement={placement}
            resizable
            site={site}
          />
        </div>
      </section>
    );
  },
);
MidCenterAd.displayName = "MidCenterAd";
