"use client";

import { Box, Text, Image, VStack, Icon } from "@yahoo/uds";
import { ChevronLeft, ChevronRight, Pause, Play } from "@yahoo/uds-icons";
import { I13nButton, I13nAnchor } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState, type FC } from "react";
import { useIntl } from "react-intl";
import AuthorBorder from "@/svgs/authorBorder.svg";
import "./authorBorder.css";
import { type BlockListItem } from "@/types/Experience";

interface Props {
  authorData: BlockListItem[];
}

const buttonClasses =
  "inline-flex items-center justify-center gap-x-2 rounded-full border-thin border-[#ebe5ff] bg-hendrix p-2 hover:border-[#e1d9ff] hover:bg-[#ebe5ff] active:border-[#cac2ff] active:bg-[#d5cfff]";

const iconClasses = "text-uh-purple active:text-[#5015b0]";

export const AuthorSlides: FC<Props> = ({ authorData }) => {
  // 8 seconds which matches the css border animation time
  const intervalTime = 8000;
  const numSlides = authorData.length;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [remainingTime, setRemainingTime] = useState(intervalTime);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number | null>(null);
  const animationDivRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();

  // Reset the border animation by removing and adding the data attribute
  const resetAnimation = () => {
    if (animationDivRef.current) {
      animationDivRef.current.removeAttribute("data-animation");
      // Trigger reflow
      void animationDivRef.current.offsetWidth;
      animationDivRef.current.setAttribute("data-animation", "true");
    }
  };

  // Go to the next or previous slide
  const goToSlide = (gotoIndex: number) => () => {
    if (gotoIndex >= numSlides) {
      setCurrentIndex(0);
    } else if (gotoIndex < 0) {
      setCurrentIndex(numSlides - 1);
    } else {
      setCurrentIndex(gotoIndex);
    }
    // Reset the animation after each slide change
    resetAnimation();
  };

  // Reset the interval with the new time
  const resetInterval = useCallback(
    (time: number) => {
      // Clear the interval if it exists
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      // Set the interval to the new time
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % numSlides);
        // Reset to intervalTime after each slide change
        setRemainingTime(intervalTime);
      }, time);
    },
    [intervalTime, numSlides],
  );

  useEffect(() => {
    resetAnimation();
  }, []);

  useEffect(() => {
    if (!isPaused) {
      // If the interval is paused, calculate the remaining time
      startTimeRef.current = Date.now();
      resetInterval(remainingTime);
    } else if (intervalRef.current) {
      // Clear the interval if it exists
      clearInterval(intervalRef.current);
    }

    // Clear the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isPaused, remainingTime, resetInterval]);

  useEffect(() => {
    // Reset the interval when the currentIndex changes
    startTimeRef.current = Date.now();
    resetInterval(intervalTime);
    setRemainingTime(intervalTime);
  }, [currentIndex, resetInterval]);

  const pauseSlides = () => {
    setIsPaused((prevIsPaused) => {
      // Clear the interval if it exists and calculate the remaining time
      if (intervalRef.current && !prevIsPaused) {
        clearInterval(intervalRef.current);
        const elapsedTime = Date.now() - (startTimeRef.current || 0);
        setRemainingTime(remainingTime - elapsedTime);
      }

      return !prevIsPaused;
    });
  };

  return (
    <>
      {authorData && (
        <VStack className="relative items-center pb-8 lg:flex-1 lg:content-center lg:py-4">
          <div
            ref={animationDivRef}
            className="relative min-h-[406px] w-full sm:min-h-[264px] lg:min-h-[224px] xl:min-h-[264px]"
            data-is-paused={isPaused ? "true" : "false"}
            data-animation=""
          >
            <ul className="flex flex-col items-center">
              {authorData.map((editor, index) => (
                <li
                  className="pointer-events-none absolute flex flex-col items-center opacity-0 transition-opacity duration-1000 data-[active-slide=active]:pointer-events-auto data-[active-slide=active]:opacity-100 sm:flex-row sm:justify-center"
                  key={`${index}-${editor.title}`}
                  data-active-slide={index === currentIndex ? "active" : ""}
                  aria-hidden={index !== currentIndex}
                >
                  <div>
                    <AuthorBorder className="visible absolute left-[2px] top-[2px] size-[288px] scale-[1.03] sm:size-[264px] lg:left-px lg:top-px lg:size-[225px] xl:left-[2px] xl:top-[2px] xl:size-[264px]" />
                    <Image
                      className="relative z-10 mb-3 size-[288px] rounded-[31px] sm:size-[264px] sm:rounded-[29px] lg:size-[224px] lg:rounded-[24px] xl:size-[264px] xl:rounded-[29px]"
                      src={editor.image?.url || ""}
                      alt={`${editor.title} ${editor.titleLineTwo}`}
                      loading="lazy"
                    />
                  </div>

                  <Box className="flex-col items-center sm:ml-6 sm:w-[145px] sm:items-start">
                    <Text className="!text-[21px] !font-semibold leading-relaxed sm:flex sm:flex-col sm:!text-[24px] sm:leading-[30px]">
                      <span>{editor.title}</span>
                      <span className="pb-1"> {editor.titleLineTwo}</span>
                    </Text>

                    <Text className="text-balance !text-[15px] leading-[20px] !text-battleship sm:tracking-normal">
                      {editor.description}
                    </Text>

                    {editor.url && (
                      <I13nAnchor
                        className="text-nowrap pt-2 text-sm font-semibold text-uh-purple"
                        href={editor.url}
                        dataYlk={{
                          elm: "link",
                          itc: "0",
                          slk: intl.formatMessage(
                            { id: "exp.VIEW_AUTHOR_PROFILE" },
                            { firstName: editor.title },
                          ),
                        }}
                      >
                        {intl.formatMessage(
                          { id: "exp.VIEW_AUTHOR_PROFILE" },
                          { firstName: editor.title },
                        )}
                      </I13nAnchor>
                    )}

                    <Box className="items-center pt-4 sm:pt-6">
                      <I13nButton
                        className={classNames("mr-1", buttonClasses)}
                        onClick={goToSlide(currentIndex - 1)}
                        aria-label="Previous slide"
                        tabIndex={index === currentIndex ? 0 : -1}
                        dataYlk={{
                          ct: "slideshow",
                          elm: "slider-dot",
                          itc: "1",
                          slk: "prev",
                        }}
                      >
                        <Icon
                          size="md"
                          variant="fill"
                          color="brand"
                          className={classNames(iconClasses)}
                          name={ChevronLeft}
                        />
                      </I13nButton>

                      <I13nButton
                        className={classNames(buttonClasses)}
                        onClick={goToSlide(currentIndex + 1)}
                        aria-label="Next slide"
                        tabIndex={index === currentIndex ? 0 : -1}
                        dataYlk={{
                          ct: "slideshow",
                          elm: "slider-dot",
                          itc: "1",
                          slk: "next",
                        }}
                      >
                        <Icon
                          size="md"
                          variant="fill"
                          color="brand"
                          className={classNames(iconClasses)}
                          name={ChevronRight}
                        />
                      </I13nButton>

                      <span className="mx-2.5 block h-[34px] w-px bg-nude-2"></span>

                      <I13nButton
                        className={classNames(buttonClasses, {
                          hidden: !isPaused,
                        })}
                        onClick={pauseSlides}
                        aria-label="Play slides"
                        aria-describedby="playing"
                        tabIndex={index === currentIndex ? 0 : -1}
                        dataYlk={{
                          ct: "slideshow",
                          elm: "play",
                          itc: "1",
                          slk: "video-play",
                        }}
                      >
                        <Icon
                          size="md"
                          variant="fill"
                          color="brand"
                          className={classNames(iconClasses)}
                          name={Play}
                        />
                      </I13nButton>
                      <I13nButton
                        className={classNames(buttonClasses, {
                          hidden: isPaused,
                        })}
                        onClick={pauseSlides}
                        aria-label="Pause slides"
                        aria-describedby="paused"
                        tabIndex={index === currentIndex ? 0 : -1}
                        dataYlk={{
                          ct: "slideshow",
                          elm: "pause",
                          itc: "1",
                          slk: "video-pause",
                        }}
                      >
                        <Icon
                          size="md"
                          variant="fill"
                          color="brand"
                          className={classNames(iconClasses)}
                          name={Pause}
                        />
                      </I13nButton>
                    </Box>
                  </Box>
                </li>
              ))}
            </ul>
          </div>
        </VStack>
      )}
    </>
  );
};
