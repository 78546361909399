"use client";

import { useDeviceSize } from "@yahoo-commerce/util";
import { useMemo } from "react";
import { useBenjiConfig } from "@/components/monetization/useBenjiConfig";
import { getBenjiConfigForCategory } from "@/lib/i13n";
import { useRequestContext } from "@/lib/request/client";

export const CategoryBenjiConfig = () => {
  const deviceSize = useDeviceSize({ updateOnResize: true });
  const requestContext = useRequestContext();

  const categoryConfig = useMemo(() => {
    if (deviceSize) {
      return getBenjiConfigForCategory({
        deviceSize,
        requestContext,
      });
    }
  }, [deviceSize, requestContext]);

  useBenjiConfig({
    benjiConfig: categoryConfig,
    clearKeys: true,
    deviceSize,
    enableAdStacking: true,
    refreshLimit: 100,
    taboolaSetting: {
      pageType: "category",
      pageTypeValue: "auto",
      publisherId: "yahooweb-network",
    },
  });

  return null;
};
