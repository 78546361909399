import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC } from "react";
import TopStoresEditorialCard from "@/components/stream/TopStoresEditorialCard";

export interface EditorialStoriesProps {
  articles: CarmotData[];
  dataYlk?: YlkData;
}

interface HeadingInColumnProps {
  heading: string;
  subtitle?: string;
}

export interface EditorialsProps {
  editorials: CarmotData[];
  dataYlk?: YlkData;
}

export const HeadingInColumn: FC<HeadingInColumnProps> = ({
  heading,
  subtitle,
}) => {
  return (
    <div className="mb-7 hidden gap-0.5 text-center xl:block">
      <h2 className="font-centra text-[1.125rem] font-bold leading-[1.4375rem] text-primary">
        {heading}
      </h2>
      {subtitle && (
        <h3 className="sub-headline-14px !text-battleship">{subtitle}</h3>
      )}
    </div>
  );
};

export const EditorialStories: FC<EditorialStoriesProps> = ({
  articles,
  dataYlk = {},
}) => {
  return (
    <ul className={classNames("hidden grid-cols-1 gap-6 xl:grid")}>
      {articles.map((article, idx) => (
        <li key={idx} className="col-span-full">
          <TopStoresEditorialCard
            article={article}
            dataYlk={{ cpos: idx + 1, ...dataYlk }}
          />
        </li>
      ))}
    </ul>
  );
};
