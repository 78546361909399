import { type CarmotAuthor } from "@yahoo-commerce/api";
import Image from "next/image";
import { type FC } from "react";
import { getImageByTag, ImageTags } from "@/lib/contentGateway/util";

type AttributionProps = {
  author?: CarmotAuthor;
};

const Attribution: FC<AttributionProps> = ({ author = {} }) => {
  const { image, displayName } = author || {};

  if (!author && !image?.carmotMysterioImages) return null;

  const resizedImage = getImageByTag({
    carmotMysterioImages: image?.carmotMysterioImages!,
    tag: ImageTags["1x1_SM"],
  });

  const imageUrl = resizedImage?.url;

  return (
    <div className="truncate leading-[0]">
      {imageUrl && (
        <Image
          alt=""
          className="mr-1.5 inline rounded-full"
          src={imageUrl}
          height="20"
          unoptimized
          width="20"
        />
      )}
      {displayName && (
        <span className="label whitespace-nowrap">{displayName}</span>
      )}
    </div>
  );
};

export default Attribution;
