import { Icon } from "@yahoo/uds";
import { Error } from "@yahoo/uds-icons";
import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";

interface ErrorMessageProps {
  id: string;
  messageId: string;
  additionalClasses?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
  id,
  messageId,
  additionalClasses,
}: ErrorMessageProps) => {
  const intl = useIntl();

  return (
    <div
      className={classNames(
        "flex items-center text-xs text-[#d30d2e]",
        additionalClasses,
      )}
    >
      <Icon name={Error} size="sm" color={"error" as any} />
      <span id={`${id}-textInputError`} role="alert" className="ml-1">
        {intl.formatMessage({ id: messageId })}
      </span>
    </div>
  );
};

export default ErrorMessage;
