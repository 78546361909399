import { type CarmotData } from "@yahoo-commerce/api";
import { type I13NSec, useRapid, type YlkData } from "@yahoo-commerce/i13n";
import { type ScreenSize } from "@yahoo-commerce/util";
import { type FC } from "react";
import Attribution from "@/components/common/Attribution";
import { CarmotThumbnailPic } from "@/components/common/CarmotThumbnail";
import { Heading } from "@/components/stream/ArticleCategoryCard";
import { ImageTags } from "@/lib/contentGateway/util";

interface NTKGridProps {
  id: string;
  stories: CarmotData[];
  dataYlk?: YlkData;
}

const SEC: I13NSec = "top-stories";
const CARD_COUNT = 4;
const heroImageSizes = {
  "2xl": ImageTags["16x9_MD"],
  lg: ImageTags["16x9_MD"],
  md: ImageTags["4x3_MD"],
  sm: ImageTags["4x3_MD"],
  xl: ImageTags["16x9_MD"],
  xs: ImageTags["16x9_SM"],
} satisfies Record<ScreenSize, ImageTags>;

const cardImageSizes = {
  "2xl": ImageTags["16x9_MD"],
  lg: ImageTags["16x9_MD"],
  md: ImageTags["16x9_MD"],
  sm: ImageTags["16x9_MD"],
  xl: ImageTags["16x9_MD"],
  xs: ImageTags["1x1_SM"],
} satisfies Record<ScreenSize, ImageTags>;

const FourFeatured: FC<NTKGridProps> = ({ id, stories, dataYlk = {} }) => {
  const [hero, ...restArticles] = stories;
  const { mpos } = useRapid(id, SEC);

  return (
    <div id={id} className="grid w-full gap-6 px-4 sm:px-6 xl:pr-0">
      <article className="group relative grid h-fit grid-cols-1 gap-5 overflow-hidden rounded-md border border-[#eceff2] sm:grid-cols-[48.6%_auto] md:grid-cols-[65.5%_auto] md:gap-5 lg:grid-cols-[57%_auto] xl:grid-cols-[65.6%_auto]">
        <div className="relative aspect-video shrink-0 sm:aspect-[4/3] lg:aspect-video xl:aspect-[4/3]">
          <CarmotThumbnailPic
            alt={hero?.title!}
            className="relative size-full object-cover"
            imageSizes={heroImageSizes}
            thumbnail={hero?.thumbnail!}
            priority
          />
        </div>
        <div className="flex min-w-0 flex-1 flex-col gap-1.5 px-5 pb-5 sm:py-5 sm:pl-0 md:gap-2">
          <Heading
            title={hero.title}
            canonicalUrl={
              hero.clickThroughUrl?.url
                ? hero.clickThroughUrl
                : hero.canonicalUrl
            }
            className="headline-text-21px md:headline-text-24px xl:headline-text-24px line-clamp-4 md:line-clamp-6 lg:line-clamp-4 xl:line-clamp-6"
            dataYlk={{ cpos: 1, ...dataYlk }}
            level={2}
          />
          <p className="sub-headline-15px md:sub-headline-15px line-clamp-3 tracking-[0.3px] sm:line-clamp-4 lg:line-clamp-3 xl:line-clamp-4">
            {hero.summary}
          </p>
          <Attribution author={hero?.author!} />
        </div>
      </article>
      <div className="grid gap-6 sm:grid-cols-3 lg:gap-8 xl:gap-6">
        {restArticles.slice(0, CARD_COUNT).map((article, idx) => (
          <article
            key={idx}
            className="group relative grid grid-cols-[100px_auto] gap-3 overflow-hidden rounded-md sm:col-span-1 sm:grid-cols-1 sm:border sm:border-[#eceff2] md:grid-cols-1"
          >
            <div className="relative shrink-0">
              <CarmotThumbnailPic
                alt={article?.title!}
                className="relative size-full h-[100px] rounded object-cover sm:h-auto xl:h-full"
                imageSizes={cardImageSizes}
                thumbnail={article?.thumbnail!}
                priority
              />
            </div>
            <div className="flex min-w-0 flex-1 flex-col gap-1.5 sm:px-3 sm:pb-4">
              <Heading
                title={article.title}
                canonicalUrl={
                  article.clickThroughUrl?.url
                    ? article.clickThroughUrl
                    : article.canonicalUrl
                }
                className="headline-text-16px sm:headline-text-17px line-clamp-4 md:line-clamp-6 lg:line-clamp-3"
                dataYlk={{ cpos: idx + 2, mpos, ...dataYlk }}
                level={2}
              />
              <Attribution author={article?.author!} />
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default FourFeatured;
