import { Button, Box, VStack } from "@yahoo/uds";
import {
  getDataYlkString,
  I13nAnchor,
  useRapid,
  type YlkData,
} from "@yahoo-commerce/i13n";
import classNames from "classnames";
import Image from "next/image";
import { type FC, useState } from "react";
import {
  buttonClasses,
  CopyButton,
  shopNowDataYlk,
} from "@/components/common/copyButton/CopyButton";
import {
  ExpiredDate,
  shouldShowExpiredDate,
} from "@/components/expModules/common/carousel/CarouselDealCard";
import { ShoppingExtPromo } from "@/components/expModules/FourFeaturedNDeals/ShoppingExtension";
import { useShoppingExtension } from "@/hooks/useShoppingExtension";
import { type Deal, DEAL_SET } from "@/types/Accio";
import { type PageModule } from "@/types/Experience";

interface Props {
  className?: string;
  deals: Deal[];
  dataYlk: YlkData | undefined;
  id: string;
  isChromeDesktop: boolean;
  module: PageModule;
}

interface DealProps {
  deal: Deal;
  showPromo: boolean;
  dataYlk: YlkData | undefined;
}

const DEALS_COUNT = 6;
const SEC = "deals-carousel";
const Deal: FC<DealProps> = ({ deal, showPromo, dataYlk }) => {
  const [isActive, setIsActive] = useState(false);
  const itemDataYlk = {
    aid: deal?.id,
    pdid: deal?.title,
    pid: deal?.id,
    seller: deal?.retailer?.id,
    sellerN: deal?.retailer?.name,
    trk_code: deal?.couponCode,
    trk_pct: deal?.promotions?.[0]?.value,
  };

  return (
    <VStack
      className={classNames("grid-internal-border md:m-0 lg:[--gap-y:64px]", {
        "xl:last:hidden": showPromo,
      })}
    >
      <Box className="min-h-11 justify-between">
        <div className="mr-4">
          <h3
            className={classNames(
              "headline-text-15px mb-2.5 line-clamp-3 xl:line-clamp-2",
              { "!line-clamp-2": isActive },
            )}
          >
            {deal.title}
          </h3>
          <Box className="items-center truncate leading-[0]">
            <Image
              alt={deal.title}
              className="mr-1.5 inline rounded-full"
              src={deal.retailer.logoUrlsBySizes[0]["50x50"]}
              height="24"
              unoptimized
              width="24"
            />
            <span
              className={classNames(
                "label whitespace-nowrap after:mx-1 after:text-battleship",
                {
                  "after:content-['·']": shouldShowExpiredDate(deal.endTime),
                },
              )}
            >
              {deal.retailer.name}
            </span>
            <ExpiredDate expiredDate={deal.endTime} />
          </Box>
        </div>
        <div>
          {deal.couponCode && (
            <CopyButton
              couponCode={deal.couponCode}
              merchantName={deal.retailer.name}
              dataYlk={{
                ...itemDataYlk,
                ...dataYlk,
              }}
              redirectUrl={deal.redirectUrl}
              reverse
              callback={() => setIsActive(true)}
            />
          )}
          {deal.sourceType == DEAL_SET.SALE && (
            <I13nAnchor
              href={deal.redirectUrl}
              dataYlk={{
                ...itemDataYlk,
                ...shopNowDataYlk,
                ...dataYlk,
              }}
              target="_blank"
            >
              <Button
                aria-label={`Shop now at ${deal.retailer.name}`}
                data-ylk={getDataYlkString({
                  ...itemDataYlk,
                  ...shopNowDataYlk,
                  ...dataYlk,
                })}
                variant="tertiary"
                className={classNames(
                  buttonClasses,
                  "h-11 border-thin !bg-white",
                )}
                tabIndex={-1}
              >
                Shop now
              </Button>
            </I13nAnchor>
          )}
        </div>
      </Box>
    </VStack>
  );
};

export const Deals: FC<Props> = ({
  className,
  deals,
  id,
  isChromeDesktop,
  dataYlk,
  module,
}) => {
  const {
    moduleRightRailTitle: title,
    moduleRightRailDescription: description,
    moduleRightRailPromo: promoTitle,
    moduleRightRailPromoURL: promoUrl,
  } = module ?? {};
  const { mpos } = useRapid(id, SEC);
  const { showPromo } = useShoppingExtension({
    isChromeDesktop,
  });

  const showShoppingExtension = showPromo && !!promoTitle && !!promoUrl;

  return (
    <div
      id={id}
      className={classNames(
        className,
        "mx-4 rounded-lg bg-[#f8f6f4] px-4 py-6 sm:mx-6 sm:px-6 sm:py-8 xl:ml-0",
      )}
    >
      <h2 className="text-[1.4375rem] font-semibold leading-[1.8125rem] text-inkwell sm:text-2xl md:text-center md:font-[700] lg:text-left">
        {title}
      </h2>
      <h3 className="sub-headline-15px mb-6 !text-battleship md:mb-10 md:text-center lg:text-left xl:mb-8">
        {description}
      </h3>
      <VStack className="gap-10 overflow-hidden md:grid md:grid-cols-2 md:gap-12 md:pb-5 lg:gap-x-16 xl:grid-cols-1 xl:gap-10">
        {deals?.slice(0, DEALS_COUNT).map((deal, idx) => {
          return (
            <Deal
              key={idx}
              deal={deal}
              showPromo={showShoppingExtension}
              dataYlk={{
                cpos: idx + 1,
                elmt: deal.provider,
                itc: 0,
                mpos,
                ...dataYlk,
              }}
            />
          );
        })}
      </VStack>
      {showShoppingExtension && (
        <ShoppingExtPromo title={promoTitle} url={promoUrl} />
      )}
    </div>
  );
};
