import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import Attribution from "@/components/common/Attribution";
import { CarmotThumbnailPic } from "@/components/common/CarmotThumbnail";
import { Heading, imageSizes } from "@/components/stream/ArticleCategoryCard";

interface StackedCard {
  article: CarmotData;
  dataYlk?: YlkData;
  showSection: boolean;
}

const StackedCard: FC<StackedCard> = ({ article, dataYlk, showSection }) => {
  return (
    <article className="relative grid grid-cols-[110px_auto] gap-3 md:grid-cols-2 md:gap-4 lg:grid-cols-9 xl:grid-cols-[292px_auto]">
      <div className="relative aspect-square md:aspect-auto lg:col-span-4 xl:col-auto">
        <CarmotThumbnailPic
          className="relative size-full rounded object-cover"
          alt={article?.title!}
          imageSizes={imageSizes}
          thumbnail={article?.thumbnail!}
        />
      </div>
      <div className="flex flex-col gap-1 lg:col-span-5 xl:col-auto">
        {showSection && (
          <span className="block text-[13px] uppercase leading-[16px] text-battleship font-weight-semibold">
            {article?.siteSectionItem?.sectionName}
          </span>
        )}
        <div className="flex flex-col gap-1 md:gap-2">
          <Heading
            title={article.title}
            canonicalUrl={
              article.clickThroughUrl?.url
                ? article.clickThroughUrl
                : article.canonicalUrl
            }
            className="headline-text-16px md:headline-text-20px font-medium"
            dataYlk={dataYlk}
          />
          <p className="hidden text-[15px] leading-5 text-batcave lg:block">
            {article.description}
          </p>
          {article?.author && <Attribution author={article.author} />}
        </div>
      </div>
    </article>
  );
};

export default StackedCard;
