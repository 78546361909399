import { type FC } from "react";

interface FormSubmitSuccessProps {
  title: string;
  subtitle: string;
}

const FormSubmitSuccess: FC<FormSubmitSuccessProps> = ({ title, subtitle }) => {
  return (
    <div className="flex grow flex-col justify-center py-8">
      <p className="text-center font-centra text-2xl font-bold text-primary sm:text-left">
        {title}
      </p>
      <p className="mt-2 text-center text-[1.0625rem] leading-[1.3125rem] text-primary sm:text-left">
        {subtitle}
      </p>
    </div>
  );
};

export default FormSubmitSuccess;
