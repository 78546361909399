"use client";

import {
  getDataYlkString,
  useRapid,
  type I13NSec,
  type YlkData,
} from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC, type ReactNode } from "react";
import PrimaryHeading from "@/components/stream/PrimaryHeading";

interface SectionProps {
  children: ReactNode;
  className?: string;
  dataYlk?: YlkData;
  heading: string;
  id?: string;
  i13nSec: I13NSec;
  subtitle?: string;
  url?: string;
  pagination?: ReactNode;
}

const Section: FC<SectionProps> = ({
  children,
  className,
  dataYlk,
  heading,
  id,
  i13nSec,
  subtitle,
  url,
  pagination,
}) => {
  const rapidId = id || i13nSec;
  const { mpos } = useRapid(rapidId, i13nSec);
  return (
    <section
      className={classNames(
        "m-auto my-10 w-full px-4 sm:px-6 lg:px-8",
        className,
      )}
      id={rapidId}
      data-pw={heading}
      data-ylk={getDataYlkString({ mpos, sec: i13nSec, ...dataYlk })}
    >
      <header
        className={classNames({
          "flex flex-row items-center justify-between": !!pagination,
        })}
      >
        <PrimaryHeading
          dataYlk={{ mpos, ...dataYlk }}
          heading={heading}
          subtitle={subtitle}
          url={url}
        />
        {pagination}
      </header>
      {children}
    </section>
  );
};

export default Section;
