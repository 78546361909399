import { Icon } from "@yahoo/uds";
import { CrossCircle, Calendar, Envelope } from "@yahoo/uds-icons";
import { I13nButton, type I13NSec, useRapid } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC, type ChangeEvent, type FormEventHandler } from "react";
import { useIntl } from "react-intl";
import ErrorMessage from "@/components/expModules/TextInputField/ErrorMessage";
import TermsLink from "@/components/expModules/TextInputField/TermsLink";
import { useTextInputFieldContext } from "@/components/expModules/TextInputField/TextInputFieldContext";
import { postNewsletterSignUp } from "@/lib/zeta/clientApi";
import LoaderIcon from "@/svgs/loader.svg";
import { type PageModule } from "@/types/Experience";

interface InputFormProps {
  id: I13NSec;
  module: PageModule;
}

/**
 * The Input Form that handles Newsletter Signup.
 *
 * NOTE: There is a reported React bug where Browser Extensions break hydration. Reported here: https://nextjs.org/docs/messages/react-hydration-error
 * Currently the suppressHydrationWarning is used to suppress the warning until the bug is fixed. This is not ideal but the other options involve render parts of the form only on the Client.
 * Rather than punish users without extensions, we are opting to suppress the warning.
 */
const InputForm: FC<InputFormProps> = ({ id, module }: InputFormProps) => {
  const sec: I13NSec = "newsletter-v2";
  const intl = useIntl();
  const { mpos } = useRapid(id, sec);

  const {
    disableSubmitButton,
    emailInputValue,
    formState,
    errorState,
    setDisableSubmitButton,
    setEmailInputValue,
    setErrorState,
    setFormState,
  } = useTextInputFieldContext();

  const dataYlk = {
    elm: "subscribe",
    itc: 0,
    mpos,
  };

  const brandId = "thecheckout";

  const onEmailInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    setEmailInputValue(target.value);
    // allows for styling the submit button differently when the input is valid e.g `disabled:bg-white bg-black` when the form input isn't valid yet
    if (target.checkValidity()) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
    if (formState === "error" && !target.value) {
      setFormState("initial");
      setErrorState(null);
    }
  };

  const resetEmailInput = () => {
    setEmailInputValue("");
  };

  // Handle the Form Submit
  const onFormSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setDisableSubmitButton(true);
    setFormState("loading");
    setErrorState(null);

    const formData: FormData = new FormData(event.target as HTMLFormElement);

    const email = formData.get("email") || emailInputValue;
    if (!email) {
      setFormState("error");
      setErrorState("email");
    }

    try {
      const response = await postNewsletterSignUp({ formData });
      if (!response || !response.success) {
        throw new Error("Failed to sign up for newsletter");
      }
      setDisableSubmitButton(false);
      setFormState("success");
      setErrorState(null);
    } catch (_) {
      setDisableSubmitButton(true);
      setFormState("error");
      setErrorState("generic");
    }
  };

  return (
    <div className="flex flex-col items-center sm:shrink sm:grow sm:items-start">
      <div className="mb-2 flex text-[0.8125rem] leading-4 text-battleship">
        {module.moduleEyebrow?.eyebrowIcon === "calendar" && (
          <Icon
            name={Calendar}
            size="sm"
            color={"battleship" as any}
            className="mr-1"
          />
        )}
        <span id={`${id}-eyebrowTxt`}>{module.moduleEyebrow?.eyebrowText}</span>
      </div>
      <h2
        id={`${id}-newsletterTitle`}
        className="flex items-center font-centra text-xl font-bold leading-[1.8125rem] text-primary md:text-[23px] md:font-[700] md:leading-7"
        aria-labelledby={`${id}-newsletterTitle ${id}-eyebrowTxt`}
      >
        {module.moduleTitle}
      </h2>
      <p className="my-1 text-center text-[1.0625rem] tracking-[0.3px] text-primary sm:text-left md:tracking-normal">
        {module.moduleSubTitle}
      </p>
      <form className="flex-col sm:flex-row" onSubmit={onFormSubmit}>
        <label
          className={classNames(
            formState === "error" ? "text-[#d30d2e]" : "text-battleship",
            "mb-2 mt-4 flex text-sm font-semibold",
          )}
          htmlFor={`${id}-inputTxt`}
        >
          {module.moduleCtaText}
        </label>
        <div
          className="grid w-auto auto-rows-max grid-cols-1 gap-3 sm:w-fit sm:grid-cols-[minmax(0,_1fr)_max-content] sm:gap-1.5"
          suppressHydrationWarning={true}
        >
          <fieldset className="relative order-1 sm:w-56 sm:max-w-max md:w-[335px]">
            {module.moduleInputForm?.inputIcon === "envelope" && (
              <Icon
                name={Envelope}
                size="sm"
                className={classNames(
                  formState === "error" ? "text-[#d30d2e]" : "text-battleship",
                  "absolute left-4 top-1/2 size-3.5 -translate-y-1/2",
                )}
                color="current"
                variant="outline"
              />
            )}
            <input
              type="email"
              id={`${id}-inputTxt`}
              name="email"
              className="w-full rounded border border-dirty-seagull px-[38px] py-2 text-sm !leading-[1.375rem] placeholder:text-battleship"
              placeholder={module.moduleInputForm?.placeholderText}
              onChange={onEmailInputChange}
              value={emailInputValue}
              aria-describedby={
                formState === "error"
                  ? `${id}-textInputError`
                  : `${id}-newsletterTitle ${id}-eyebrowTxt`
              }
            />
            <button
              className="absolute right-4 top-1/2 -translate-y-1/2 text-battleship"
              type="reset"
              aria-label="Clear email input"
              onClick={resetEmailInput}
            >
              <Icon
                name={CrossCircle}
                className="size-3.5"
                size="sm"
                variant="fill"
                color="current"
              />
            </button>
          </fieldset>
          <input type="hidden" name="brandId" value={brandId} />
          {formState === "error" && (
            <ErrorMessage
              id={id}
              additionalClasses="order-2 sm:order-3 sm:col-span-2 my-1"
              messageId={
                errorState === "email"
                  ? "newsletter.INVALID_EMAIL"
                  : "newsletter.GENERIC_ERROR"
              }
            />
          )}
          <TermsLink
            termsId={module.moduleInputForm?.termsText ?? ""}
            additionalClasses="order-4 sm:col-span-2"
          />
          <I13nButton
            className="order-3 grow-0 whitespace-nowrap rounded-full border bg-spectrum-color-purple-9 px-5 py-3.5 text-sm font-semibold text-white transition-transform duration-[220ms] hover:scale-[1.03] hover:bg-spectrum-color-purple-10 active:scale-[0.97] active:bg-spectrum-color-purple-11 disabled:bg-dirty-seagull disabled:bg-none disabled:text-shark sm:order-2 sm:col-start-2 sm:py-2.5"
            dataYlk={{
              ...dataYlk,
              elmt: formState === "error" ? "error" : "success",
            }}
            type="submit"
            disabled={disableSubmitButton}
            aria-describedby="textInputFieldTermsLink"
          >
            {formState === "loading" && (
              <LoaderIcon className="mr-1 inline-block size-4 animate-spin" />
            )}
            {intl.formatMessage({
              id:
                formState === "loading"
                  ? "newsletter.SUBMIT"
                  : "newsletter.SIGN_UP",
            })}
          </I13nButton>
        </div>
      </form>
    </div>
  );
};

export default InputForm;
