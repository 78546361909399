import { Box } from "@yahoo/uds";
import { I13nLink, type YlkData } from "@yahoo-commerce/i13n";
import { formatNumber } from "@yahoo-commerce/util";
import classNames from "classnames";
import { type FC } from "react";
import NCPThumbnail from "@/components/common/NCPThumbnail";
import { type TopMerchant } from "@/types/Accio";
import { getMerchantUrl, MERCHANT_LOGO_SIZE } from "@/utils/merchant";

interface MerchantProps {
  dataYlk: YlkData;
  merchant: TopMerchant;
}

const SIZE = "56x56";

const Merchant: FC<MerchantProps> = ({ dataYlk, merchant }) => {
  const hasDeals = merchant.couponCount > 0 || merchant.saleCount > 0;
  const hasCouponsAndSales = merchant.couponCount > 0 && merchant.saleCount > 0;
  const logoUrl =
    merchant?.resizedLogoUrls?.[0][MERCHANT_LOGO_SIZE] ??
    merchant?.logoUrls?.[0];

  return (
    <I13nLink
      dataYlk={{
        elm: "link",
        slk: merchant.name,
        ...dataYlk,
      }}
      href={getMerchantUrl(merchant)}
    >
      <Box
        className="group flex items-center gap-3 border-dirty-seagull"
        borderWidth="thin"
        borderRadius="lg"
        spacing="4"
      >
        <NCPThumbnail
          alt={merchant.name}
          className="max-h-[48px] max-w-[48px] rounded-full border border-dirty-seagull object-cover min-[640px]:max-h-[56px] min-[640px]:max-w-[56px]"
          url={logoUrl}
          size={SIZE}
        />
        <div>
          <h5 className="headline-text-16px min-[640px]:headline-text-17px group-hover:underline">
            {merchant.name}
          </h5>
          {hasDeals && (
            <p className="mt-1 text-[15px] leading-[1.375rem] tracking-[0.32px] text-battleship font-weight-thin md:text-[0.9375rem] md:leading-5">
              {merchant.couponCount > 0 && (
                <span
                  className={classNames(
                    hasCouponsAndSales &&
                      "after:mx-2 after:mb-1 after:inline-block after:size-[3px] after:rounded-full after:bg-battleship",
                  )}
                >
                  {`${formatNumber(merchant.couponCount)}`} coupons
                </span>
              )}
              {merchant.saleCount > 0 && (
                <span>{`${formatNumber(merchant.saleCount)}`} deals</span>
              )}
            </p>
          )}
        </div>
      </Box>
    </I13nLink>
  );
};

export default Merchant;
