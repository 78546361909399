import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC } from "react";
import ArticleCategoryCard from "@/components/stream/ArticleCategoryCard";

interface ArticleCategoryProps {
  articles: CarmotData[];
  dataYlk?: YlkData;
  className?: string;
  cardClassName?: string;
  cardContentStyle?: string;
}

const ArticleCategory: FC<ArticleCategoryProps> = ({
  articles,
  dataYlk,
  className,
  cardClassName,
  cardContentStyle,
}) => {
  return (
    <ul
      className={classNames(
        "flex flex-col gap-4 md:grid md:grid-cols-4 md:gap-6",
        className,
      )}
    >
      {articles.map((article, idx) => (
        <li
          key={idx}
          className={classNames(
            "col-span-full rounded-lg md:col-span-1",
            cardClassName,
          )}
        >
          <ArticleCategoryCard
            article={article}
            dataYlk={{ cpos: idx + 1, ...dataYlk }}
            contentStyle={cardContentStyle}
          />
        </li>
      ))}
    </ul>
  );
};

export default ArticleCategory;
