import {
  type MysterioImage,
  MysterioImageFormat,
  type MysterioTransformsInput,
  MysterioImageTransformOperation,
  type Maybe,
} from "@yahoo-commerce/api";

export enum ImageTags {
  "4x3_MD" = "4x3_MD",
  "5x4_MD" = "5x4_MD",
  "16x9_MD" = "16x9_MD",
  "16x10_MD" = "16x10_MD",
  "1x1_MD" = "1x1_MD",
  "1x1_SM" = "1x1_SM",
  "16x9_SM" = "16x9_SM",
}

const defaultResizeOptions: Partial<MysterioTransformsInput> = {
  formatType: MysterioImageFormat.Webp,
  operationOrder: [MysterioImageTransformOperation.ResizeFill],
  quality: 95,
};

type MysterioImageMap = { [key in ImageTags]?: MysterioTransformsInput };

export const mysterioImageSizes: MysterioImageMap = {
  [ImageTags["4x3_MD"]]: {
    ...defaultResizeOptions,
    resizeFillH: 672,
    resizeFillW: 896,
    transformLabel: ImageTags["4x3_MD"],
  },
  [ImageTags["5x4_MD"]]: {
    ...defaultResizeOptions,
    resizeFillH: 364,
    resizeFillW: 454,
    transformLabel: ImageTags["5x4_MD"],
  },
  [ImageTags["16x9_MD"]]: {
    ...defaultResizeOptions,
    resizeFillH: 504,
    resizeFillW: 896,
    transformLabel: ImageTags["16x9_MD"],
  },
  [ImageTags["16x10_MD"]]: {
    ...defaultResizeOptions,
    resizeFillH: 560,
    resizeFillW: 896,
    transformLabel: ImageTags["16x10_MD"],
  },
  [ImageTags["1x1_MD"]]: {
    ...defaultResizeOptions,
    resizeFillH: 220,
    resizeFillW: 220,
    transformLabel: ImageTags["1x1_MD"],
  },
  [ImageTags["1x1_SM"]]: {
    ...defaultResizeOptions,
    resizeFillH: 148,
    resizeFillW: 148,
    transformLabel: ImageTags["1x1_SM"],
  },
  [ImageTags["16x9_SM"]]: {
    ...defaultResizeOptions,
    resizeFillH: 384,
    resizeFillW: 682,
    transformLabel: ImageTags["16x9_SM"],
  },
};

export const getImageBySize = ({
  carmotMysterioImages = [],
  tag,
}: {
  carmotMysterioImages: Maybe<MysterioImage>[];
  tag: ImageTags;
}) => {
  const size = mysterioImageSizes[tag];
  return carmotMysterioImages
    ?.filter((image) => image !== null) // Filter out null images
    ?.find(
      (res) =>
        res.width === size?.resizeFillW && res.height === size?.resizeFillH,
    );
};

export const getImageByTag = ({
  carmotMysterioImages = [],
  tag,
}: {
  carmotMysterioImages: Maybe<MysterioImage>[];
  tag: ImageTags;
}) => {
  return carmotMysterioImages
    ?.filter((image) => image !== null) // Filter out null images
    ?.find((res) => res.transformLabel === tag);
};
