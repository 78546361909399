"use client";

import { type CarmotData } from "@yahoo-commerce/api";
import {
  getDataYlkString,
  type I13NSec,
  useRapid,
  type YlkData,
} from "@yahoo-commerce/i13n";
import { type ScreenSize } from "@yahoo-commerce/util";
import { type FC } from "react";
import Attribution from "@/components/common/Attribution";
import { CarmotThumbnailPic } from "@/components/common/CarmotThumbnail";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { Heading } from "@/components/stream/ArticleCategoryCard";
import { ImageTags } from "@/lib/contentGateway/util";

interface Props {
  dataYlk?: YlkData;
  id: string;
  restArticleLimit: number;
  stories: CarmotData[];
}

const SEC: I13NSec = "top-stories";
const SUB_SEC = "needtoknow";
const heroImageSizes = {
  "2xl": ImageTags["16x9_MD"],
  lg: ImageTags["16x9_MD"],
  md: ImageTags["4x3_MD"],
  sm: ImageTags["4x3_MD"],
  xl: ImageTags["16x9_MD"],
  xs: ImageTags["16x9_SM"],
} satisfies Record<ScreenSize, ImageTags>;

const cardImageSizes = {
  "2xl": ImageTags["16x9_MD"],
  lg: ImageTags["16x9_MD"],
  md: ImageTags["16x9_MD"],
  sm: ImageTags["16x9_MD"],
  xl: ImageTags["16x9_MD"],
  xs: ImageTags["1x1_SM"],
} satisfies Record<ScreenSize, ImageTags>;

const Grid: FC<Props> = ({ dataYlk, id, restArticleLimit, stories }) => {
  const [hero, ...restArticles] = stories || [];
  const { mpos } = useRapid(id, SEC);

  return (
    <section className="mx-auto mb-10 w-full px-4 sm:px-6 lg:px-8" id={id}>
      <div className="flex flex-col gap-6 md:grid lg:grid-cols-[auto_31%] lg:gap-8">
        <article className="group relative grid grid-cols-1 gap-5 overflow-hidden rounded-md border border-[#eceff2] sm:grid-cols-[48.6%_auto] md:grid-cols-[65.5%_auto] md:gap-5 lg:grid-cols-1">
          <div className="relative aspect-video h-full shrink-0 sm:aspect-[4/3] lg:aspect-video">
            <ErrorBoundary id="CarmotThumbnailPic_hero">
              <CarmotThumbnailPic
                alt={hero?.title!}
                className="relative size-full h-full object-cover"
                imageSizes={heroImageSizes}
                thumbnail={hero?.thumbnail!}
                priority
              />
            </ErrorBoundary>
          </div>
          <div className="flex min-w-0 flex-1 flex-col gap-1.5 px-5 pb-5 sm:py-5 sm:pl-0 md:gap-2 lg:px-5 lg:pb-5 lg:pt-0">
            <ErrorBoundary id="Heading_hero">
              <Heading
                title={hero.title}
                canonicalUrl={
                  hero.clickThroughUrl?.url
                    ? hero.clickThroughUrl
                    : hero.canonicalUrl
                }
                className="headline-text-21px md:headline-text-24px lg:headline-text-32px line-clamp-4 md:line-clamp-6 lg:line-clamp-3"
                dataYlk={{
                  cpos: 1,
                  itc: 0,
                  mpos,
                  slk: hero!.title?.replace(/[^\w\s-]/g, ""),
                  subsec: SUB_SEC,
                  ...dataYlk,
                }}
                level={2}
              />
            </ErrorBoundary>
            <p className="sub-headline-15px md:sub-headline-15px lg:sub-headline-14px line-clamp-3 tracking-[0.3px] sm:hidden md:line-clamp-4 lg:line-clamp-2 xl:line-clamp-4">
              {hero.summary}
            </p>
            <ErrorBoundary id="Attribution_hero">
              <Attribution author={hero?.author!} />
            </ErrorBoundary>
          </div>
        </article>
        <div className="grid gap-6 sm:grid-cols-2 sm:gap-6 lg:grid-cols-1 lg:gap-8">
          {restArticles.slice(0, restArticleLimit).map((article, idx) => (
            <article
              className="group relative grid grid-cols-[100px_auto] gap-3 overflow-hidden rounded-md sm:grid-cols-1 sm:border sm:border-[#eceff2] md:gap-3"
              data-ylk={getDataYlkString({ mpos, sec: SEC, ...dataYlk })}
              key={idx}
            >
              <div className="relative aspect-square h-full shrink-0 sm:aspect-video">
                <ErrorBoundary id={`CarmotThumbnailPic_${idx}`}>
                  <CarmotThumbnailPic
                    alt={article?.title!}
                    className="relative size-full rounded object-cover sm:rounded-none"
                    imageSizes={cardImageSizes}
                    thumbnail={article?.thumbnail!}
                  />
                </ErrorBoundary>
              </div>
              <div className="flex min-w-0 flex-1 flex-col gap-1.5 sm:px-3 sm:pb-4 lg:px-3 lg:pb-4">
                <ErrorBoundary id={`Heading_${idx}`}>
                  <Heading
                    title={article.title}
                    canonicalUrl={
                      article.clickThroughUrl?.url
                        ? article.clickThroughUrl
                        : article.canonicalUrl
                    }
                    className="headline-text-16px sm:headline-text-17px md:headline-text-17px line-clamp-4 sm:line-clamp-3"
                    dataYlk={{
                      cpos: idx + 2,
                      itc: 0,
                      mpos,
                      slk: article!.title?.replace(/[^\w\s-]/g, ""),
                      subsec: SUB_SEC,
                      ...dataYlk,
                    }}
                    level={2}
                  />
                </ErrorBoundary>
                <ErrorBoundary id={`Attribution_${idx}`}>
                  <Attribution author={article?.author!} />
                </ErrorBoundary>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Grid;
