"use client";

import { useRapid, getDataYlkString, type I13NSec } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import { AuthorSlides } from "@/components/expModules/TextBlockListAutoSlider/AuthorSlides";
import Card from "@/components/expModules/TextBlockListAutoSlider/Card";
import { type PageModule } from "@/types/Experience";

interface TextBlockListSliderProps {
  module: PageModule;
  i13nId: string;
}

const TextBlockListAutoSlider: FC<TextBlockListSliderProps> = ({
  module,
  i13nId,
}: TextBlockListSliderProps) => {
  const {
    moduleTitle,
    moduleSubTitle,
    moduleCtaText,
    moduleCtaURL,
    moduleBlockList,
  } = module;

  const sec: I13NSec = "trust-module";
  const { mpos } = useRapid(sec);

  return (
    <section
      className="bg-site-gradient m-auto my-10 flex flex-col rounded-lg pb-6 pt-4 sm:px-4 lg:mx-8 lg:my-4 lg:flex-row lg:items-center lg:rounded-lg lg:py-4"
      id={sec}
      data-ylk={getDataYlkString({ id: i13nId, mpos, sec })}
    >
      <Card
        title={moduleTitle ?? ""}
        description={moduleSubTitle ?? ""}
        ctaText={moduleCtaText ?? ""}
        ctaUrl={moduleCtaURL ?? ""}
      />
      <AuthorSlides authorData={moduleBlockList ?? []} />
    </section>
  );
};

export default TextBlockListAutoSlider;
