"use client";

import {
  type AdLoadingMode,
  buildResponsiveAdProps,
  ResponsiveAd,
} from "@yahoo-commerce/monetization";
import { type AdPlacement } from "@yahoo-commerce/monetization/src/Ad";
import { SCREEN_WIDTH } from "@yahoo-commerce/util";
import { memo, type FC } from "react";
import { useRequestContext } from "@/lib/request/client";

const configs = buildResponsiveAdProps([
  {
    device: "dt",
    screenWidth: { from: SCREEN_WIDTH.lg },
    sizes: [{ height: 600, width: 300 }],
  },
]);

interface Props {
  locationNumber?: number;
  loading?: AdLoadingMode;
  location?: string;
  placement?: AdPlacement;
}

// this component is memoized to prevent unnecessary rerenders of the underlying ad
export const MidRightAd: FC<Props> = memo(
  ({ locationNumber, loading, location = "mid_right", placement = "ros" }) => {
    const { site } = useRequestContext();
    return (
      <div className="mb-7 mt-5 hidden min-w-[300px] justify-start md:my-0 xl:flex">
        <ResponsiveAd
          configs={configs}
          locationNumber={locationNumber}
          loading={loading}
          location={location}
          placement={placement}
          pageRegion="index"
          resizable
          site={site}
        />
      </div>
    );
  },
);
MidRightAd.displayName = "MidRightAd";
