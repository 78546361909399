import { type CarmotData } from "@yahoo-commerce/api";
import { I13nLink, type YlkData } from "@yahoo-commerce/i13n";
import { type ScreenSize } from "@yahoo-commerce/util";
import classNames from "classnames";
import { type FC } from "react";
import Attribution from "@/components/common/Attribution";
import { CarmotThumbnailPic } from "@/components/common/CarmotThumbnail";
import { ImageTags } from "@/lib/contentGateway/util";

type HeadingProps = Pick<CarmotData, "title" | "canonicalUrl"> & {
  dataYlk?: YlkData;
  className?: string;
  level?: 2 | 3;
};

interface ArticleCategoryCard {
  article: CarmotData;
  dataYlk: YlkData;
  contentStyle?: string;
}

export const Heading: FC<HeadingProps> = ({
  canonicalUrl,
  title,
  dataYlk,
  className,
  level = 3,
}) => {
  const url = canonicalUrl?.url;
  if (!url) {
    console.error({ dataYlk: {}, msg: "MISSING_HREF" });
  }
  const HeaderTag = level === 2 ? "h2" : "h3"; // Dynamically set the heading tag

  return (
    <HeaderTag aria-label={title || ""}>
      <I13nLink
        dataYlk={{
          elm: "hdln",
          slk: title,
          ...dataYlk,
        }}
        href={url!}
        prefetch={true}
        className={classNames("stretched-box group-hover:underline", className)}
      >
        {title}
      </I13nLink>
    </HeaderTag>
  );
};

export const imageSizes = {
  "2xl": ImageTags["16x9_MD"],
  lg: ImageTags["16x9_MD"],
  md: ImageTags["16x9_MD"],
  sm: ImageTags["16x9_MD"],
  xl: ImageTags["16x9_MD"],
  xs: ImageTags["1x1_SM"],
} satisfies Record<ScreenSize, ImageTags>;

const ArticleCategoryCard: FC<ArticleCategoryCard> = ({
  article,
  dataYlk,
  contentStyle,
}) => {
  return (
    <article className="group relative grid grid-cols-[100px_auto] gap-3 sm:grid-cols-[30%_auto] sm:gap-4 md:grid-cols-1 md:gap-3">
      <div className="relative aspect-square shrink-0 sm:aspect-video">
        <CarmotThumbnailPic
          alt={article?.title!}
          className="relative size-full rounded object-cover"
          imageSizes={imageSizes}
          thumbnail={article?.thumbnail!}
        />
      </div>
      <div
        className={classNames(
          "flex min-w-0 flex-1 flex-col gap-1.5",
          contentStyle,
        )}
      >
        <Heading
          title={article.title}
          canonicalUrl={
            article.clickThroughUrl?.url
              ? article.clickThroughUrl
              : article.canonicalUrl
          }
          className="headline-text-16px sm:headline-text-18px md:headline-text-17px line-clamp-4 sm:line-clamp-3 md:line-clamp-4"
          dataYlk={{ g: article.uuid, sec: "article-carousel", ...dataYlk }}
        />
        <Attribution author={article?.author!} />
      </div>
    </article>
  );
};

export default ArticleCategoryCard;
