"use client";
import {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useState,
} from "react";

export type FormState = "initial" | "loading" | "success" | "error";
export type ErrorState = null | "generic" | "email";

interface TextInputFieldContextType {
  disableSubmitButton: boolean;
  emailInputValue: string;
  errorState: ErrorState;
  formState: FormState;
  setDisableSubmitButton: React.Dispatch<React.SetStateAction<boolean>>;
  setEmailInputValue: React.Dispatch<React.SetStateAction<string>>;
  setErrorState: React.Dispatch<React.SetStateAction<ErrorState>>;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

interface TextInputFieldProviderProps {
  children?: ReactNode;
}

export const TextInputFieldContext = createContext<TextInputFieldContextType>({
  disableSubmitButton: false,
  emailInputValue: "",
  errorState: null,
  formState: "initial",
  setDisableSubmitButton: () => {},
  setEmailInputValue: () => {},
  setErrorState: () => {},
  setFormState: () => {},
});

export const TextInputFieldProvider: FC<TextInputFieldProviderProps> = ({
  children,
}) => {
  const [formState, setFormState] = useState<FormState>("initial");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [errorState, setErrorState] = useState<ErrorState>(null);
  const [emailInputValue, setEmailInputValue] = useState("");

  return (
    <TextInputFieldContext.Provider
      value={{
        disableSubmitButton,
        emailInputValue,
        errorState,
        formState,
        setDisableSubmitButton,
        setEmailInputValue,
        setErrorState,
        setFormState,
      }}
    >
      {children}
    </TextInputFieldContext.Provider>
  );
};

export const useTextInputFieldContext = (): TextInputFieldContextType =>
  useContext(TextInputFieldContext);
