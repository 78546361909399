"use client";

import Image from "next/image";
import { type FC } from "react";
import FormSubmitSuccess from "@/components/expModules/TextInputField/FormSubmitSuccess";
import InputForm from "@/components/expModules/TextInputField/InputForm";
import { useTextInputFieldContext } from "@/components/expModules/TextInputField/TextInputFieldContext";
import { type PageModule } from "@/types/Experience";

interface TextInputFieldModuleProps {
  module: PageModule;
  i13nId: string;
}

const TextInputFieldModule: FC<TextInputFieldModuleProps> = ({
  module,
  i13nId,
}: TextInputFieldModuleProps) => {
  const { formState } = useTextInputFieldContext();
  const { moduleInputForm } = module;

  return (
    <section
      id={i13nId}
      className="mx-4 my-6 flex flex-col items-center justify-center rounded-lg bg-[#fcfbfb] p-6 sm:mx-6 sm:flex-row sm:px-10 sm:py-8"
    >
      {moduleInputForm?.inputImage && (
        <div className="mb-6 flex flex-none flex-row justify-center align-middle sm:mb-0 sm:mr-8 sm:grow sm:py-8">
          <Image
            src={moduleInputForm.inputImage.url ?? ""}
            alt=""
            className="size-[132px] flex-none rounded-lg sm:size-[167px]"
            width="167"
            height="167"
          />
        </div>
      )}
      {formState !== "success" && <InputForm module={module} id={i13nId} />}
      {formState === "success" && (
        <FormSubmitSuccess
          title={moduleInputForm?.postSubmitTitle ?? ""}
          subtitle={moduleInputForm?.postSubmitDescription ?? ""}
        />
      )}
    </section>
  );
};

export default TextInputFieldModule;
