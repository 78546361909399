import { type FC } from "react";

interface HeadingProps {
  heading: string;
  subheading: string;
}

const Heading: FC<HeadingProps> = ({ heading, subheading }) => {
  return (
    <header className="px-6 text-center text-primary">
      <h1 className="mb-2 font-centra text-3xl font-extrabold leading-10 sm:text-4xl sm:leading-[2.75rem]">
        {heading}
      </h1>
      <h2 className="text-[1.3125rem] font-[450] leading-[1.625rem] text-batcave sm:text-lg sm:leading-[1.625rem]">
        {subheading}
      </h2>
    </header>
  );
};

export default Heading;
