import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import ArticleCategory from "@/components/stream/ArticleCategory";
import PrimaryHeading from "@/components/stream/PrimaryHeading";
import {
  EditorialStories,
  HeadingInColumn,
} from "@/components/stream/TopStoresEditorialColumn";

interface ArticleRightRailProps {
  heading: string;
  subheading: string;
  editorials: CarmotData[];
  dataYlk?: YlkData;
}

const ArticleRightRail: FC<ArticleRightRailProps> = ({
  heading,
  subheading,
  editorials,
  dataYlk = {},
}) => {
  return (
    <div className="order-3 col-span-2 xl:col-span-1 xl:rounded-md xl:border xl:border-[#eceff2] xl:bg-white">
      <section className="m-auto my-10 mb-0 mt-8 grid w-full px-4 sm:px-6 lg:px-6 xl:m-0 xl:px-4 xl:pb-4 xl:pt-6">
        <PrimaryHeading
          heading={heading}
          subtitle={subheading}
          className="xl:hidden"
        />
        {/* Display only when the screen is 'lg' with a columnar layout. */}
        <HeadingInColumn heading={heading} subtitle={subheading} />
        <ArticleCategory
          articles={editorials}
          className="xl:hidden"
          cardClassName="md:border md:border-[#eceff2] md:bg-white"
          cardContentStyle="md:px-3 md:pb-4"
          dataYlk={dataYlk}
        />
        {/* Display only when the screen is 'lg' with a columnar layout. */}
        <EditorialStories articles={editorials} dataYlk={dataYlk} />
      </section>
    </div>
  );
};

export default ArticleRightRail;
