import { getDataYlkString } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";

interface TermsLinkProps {
  termsId: string;
  additionalClasses?: string;
}

const TermsLink: FC<TermsLinkProps> = ({
  termsId,
  additionalClasses,
}: TermsLinkProps) => {
  const intl = useIntl();

  const ylkString = getDataYlkString({
    elm: "link",
    itc: 0,
    sec: "newsletter v2",
  });

  const termsAndPrivacyPolicy = intl.formatMessage(
    { id: termsId },
    {
      a1: (text) =>
        `<a class="text-battleship underline font-weight-bold" data-ylk="${ylkString}" href="https://legal.yahoo.com/us/en/yahoo/terms/otos/index.html" target='_blank' rel='noopener noreferrer'>${text}</a>`,
      a2: (text) =>
        `<a class="text-battleship underline font-weight-bold" data-ylk="${ylkString}" href="https://legal.yahoo.com/us/en/yahoo/privacy/index.html" target='_blank' rel='noopener noreferrer'>${text}</a>`,
    },
  );

  return (
    <p
      id="textInputFieldTermsLink"
      className={classNames("text-xs", additionalClasses)}
      dangerouslySetInnerHTML={{ __html: termsAndPrivacyPolicy }}
    />
  );
};

export default TermsLink;
