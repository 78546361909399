import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import { type ScreenSize } from "@yahoo-commerce/util/lib/screens";
import { type FC } from "react";
import Attribution from "@/components/common/Attribution";
import { CarmotThumbnailPic } from "@/components/common/CarmotThumbnail";
import { Heading } from "@/components/stream/ArticleCategoryCard";
import { ImageTags } from "@/lib/contentGateway/util";

interface SubheadingProps {
  title: string;
}

interface ThreeFeaturedHeroProps {
  article: CarmotData;
  dataYlk?: YlkData;
}

const imageSizes = {
  "2xl": ImageTags["16x10_MD"],
  lg: ImageTags["16x10_MD"],
  md: ImageTags["4x3_MD"],
  sm: ImageTags["5x4_MD"],
  xl: ImageTags["16x10_MD"],
  xs: ImageTags["16x10_MD"],
} satisfies Record<ScreenSize, ImageTags>;

const Subheading: FC<SubheadingProps> = ({ title }) => {
  return (
    <p className="sub-headline-15px md:sub-headline-14px line-clamp-2 tracking-[0.3px] md:tracking-normal">
      {title}
    </p>
  );
};

const ThreeFeaturedHero: FC<ThreeFeaturedHeroProps> = ({
  article,
  dataYlk = {},
}) => {
  return (
    <article className="group relative grid grid-cols-1 gap-5 overflow-hidden rounded-md border border-[#eceff2] bg-white sm:grid-cols-[48.6%_auto] md:grid-cols-[65.5%_auto] md:gap-5 lg:grid-cols-1 lg:gap-0 xl:order-2">
      <div className="relative aspect-[16/10] shrink-0 sm:aspect-[5/4] md:aspect-[4/3] lg:aspect-[16/10]">
        <CarmotThumbnailPic
          alt={article?.title!}
          className="relative size-full object-cover"
          imageSizes={imageSizes}
          thumbnail={article?.thumbnail!}
          priority
        />
      </div>
      <div className="flex min-w-0 flex-1 flex-col gap-2 px-5 pb-5 sm:px-0 sm:py-5 sm:pr-4 lg:px-5">
        <Heading
          title={article.title}
          canonicalUrl={
            article.clickThroughUrl?.url
              ? article.clickThroughUrl
              : article.canonicalUrl
          }
          className="headline-text-21px md:headline-text-20px xl:headline-text-24px line-clamp-4 md:line-clamp-6 lg:line-clamp-3"
          dataYlk={dataYlk}
        />
        <Subheading title={article?.summary!} />
        <Attribution author={article?.author!} />
      </div>
    </article>
  );
};

export default ThreeFeaturedHero;
