"use client";

import { type CarmotData } from "@yahoo-commerce/api";
import { type I13NSec, useRapid, getDataYlkString } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { type PageModule } from "@/types/Experience";
import ArticleRightRail from "./ArticleRightRail";
import Heading from "./Heading";
import ThreeFeaturedGrid from "./ThreeFeaturedGrid";

type GridProps = {
  module: PageModule;
  topStories: CarmotData[];
  editorials: CarmotData[];
  moduleTitleOverride?: string;
  moduleDescriptionOverride?: string;
};

const TOP_STORIES_SEC: I13NSec = "top-stories";

export const Grid: FC<GridProps> = ({
  module,
  moduleTitleOverride,
  moduleDescriptionOverride,
  topStories,
  editorials,
}) => {
  const { mpos } = useRapid(TOP_STORIES_SEC);
  const heading = moduleTitleOverride ?? module.moduleTitle ?? "";
  const subheading = moduleDescriptionOverride ?? module.moduleSubTitle ?? "";
  const rightRailHeading = module.moduleRightRailTitle ?? "";
  const rightRailSubheading = module.moduleRightRailDescription ?? "";

  return (
    <section
      className="bg-site-gradient m-auto mb-10 rounded-b-lg pb-6 pt-8 lg:mx-8 lg:mt-4 lg:rounded-lg"
      id={TOP_STORIES_SEC}
      data-ylk={getDataYlkString({
        ct: "STORY",
        itc: 0,
        mpos,
      })}
    >
      <Heading heading={heading} subheading={subheading} />
      <div className="grid grid-cols-1 xl:grid-cols-[auto_25%] xl:gap-6 xl:px-6 xl:pb-0 xl:pt-8">
        <ErrorBoundary id="ThreeFeaturedGrid">
          <ThreeFeaturedGrid
            stories={topStories}
            dataYlk={{ sec: "top-stories", subsec: "needtoknow" }}
          />
        </ErrorBoundary>
        <div className="order-3 col-span-2 xl:col-span-1 xl:h-fit xl:rounded-lg xl:bg-white">
          <ErrorBoundary id="ArticleRightRail">
            <ArticleRightRail
              heading={rightRailHeading}
              subheading={rightRailSubheading}
              editorials={editorials}
              dataYlk={{ sec: "article-carousel", subsec: "editorspics" }}
            />
          </ErrorBoundary>
        </div>
      </div>
    </section>
  );
};
