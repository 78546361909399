"use client";

import { Box, Button, Icon } from "@yahoo/uds";
import { ChevronRight } from "@yahoo/uds-icons";
import { AppAccessibilityLabel } from "@yahoo-commerce/a11y";
import { I13nAnchor, type I13NSec, useRapid } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import { useIntl } from "react-intl";
import ShoppingExtension from "@/svgs/shoppingExtension.svg";

interface ShoppingExtPromoProps {
  title: string;
  url: string;
}

const SEC: I13NSec = "promo-banner";
export const ShoppingExtPromo: FC<ShoppingExtPromoProps> = ({ title, url }) => {
  useRapid(SEC);
  const intl = useIntl();

  return (
    <Button
      id={SEC}
      aria-label={`${title} ${AppAccessibilityLabel.INSTALL_BROWSER_EXTENSION}`}
      palette="brand"
      className="group relative ml-auto hidden p-1.5 pr-2.5 md:block md:text-right xl:mx-auto xl:mt-7 xl:text-center"
    >
      <I13nAnchor
        className="headline-text-15px stretched-box flex items-center !text-white"
        href={url}
        dataYlk={{
          elm: "hdln",
          sec: SEC,
          subsec: "promo-banner",
        }}
        target="_blank"
      >
        <span className="flex size-10 items-center justify-center rounded-full bg-[#ebe5ff]">
          <ShoppingExtension
            height="24"
            width="24"
            className="[&>path]:fill-brand"
          />
        </span>
        <Box className="mx-2 flex-col items-start">
          <span>{title}</span>
          <span className="max-h-0 text-xs font-normal opacity-0 transition-all duration-500 ease-in-out group-hover:max-h-[50px] group-hover:opacity-100">
            {intl.formatMessage({ id: "deals.SHOPPING_CTA" })}
          </span>
        </Box>
        <Icon
          size="md"
          variant="fill"
          className="text-white"
          name={ChevronRight}
        />
      </I13nAnchor>
    </Button>
  );
};
