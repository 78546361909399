"use client";

import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { Deals } from "@/components/expModules/FourFeaturedNDeals/Deals";
import { type Deal } from "@/types/Accio";
import { type PageModule } from "@/types/Experience";
import FourFeatured from "./FourFeatured";
import "./Grid.css";

interface Props {
  dataYlk?: YlkData;
  deals: Deal[];
  id: string;
  isChromeDesktop: boolean;
  module: PageModule;
  stories: CarmotData[];
}

const SUB_SEC = "needtoknow";

const FourFeatureNDealsRightRail: FC<Props> = ({
  id,
  isChromeDesktop = false,
  dataYlk,
  deals,
  module,
  stories,
}) => {
  return (
    <section data-pw={module.moduleType} className="mb-10">
      <div className="grid grid-cols-1 gap-8 xl:grid-cols-[auto_31%] xl:gap-8 xl:pb-0">
        <ErrorBoundary id="FourFeatured">
          <FourFeatured
            id={id}
            stories={stories}
            dataYlk={{ itc: 0, subsec: SUB_SEC, ...dataYlk }}
          />
        </ErrorBoundary>
        <ErrorBoundary id="Deals">
          <Deals
            className="self-start"
            dataYlk={dataYlk}
            deals={deals}
            id={`${id}_deals`}
            isChromeDesktop={isChromeDesktop}
            module={module}
          />
        </ErrorBoundary>
      </div>
    </section>
  );
};

export default FourFeatureNDealsRightRail;
