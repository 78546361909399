import { Text, VStack } from "@yahoo/uds";
import { I13nAnchor } from "@yahoo-commerce/i13n";
import { type FC } from "react";

interface TextBlockListCardProps {
  title: string;
  description: string;
  ctaText: string;
  ctaUrl: string;
}

const TextBlockListCard: FC<TextBlockListCardProps> = ({
  title,
  description,
  ctaText,
  ctaUrl,
}: TextBlockListCardProps) => {
  return (
    <VStack className="px-6 pb-12 pt-8 text-center sm:px-9 md:px-12 lg:flex-1 lg:py-12 lg:text-left xl:py-16 2xl:px-14 2xl:py-20">
      <Text
        variant="title2"
        spacingBottom="2.5"
        className="!font-centra leading-9 sm:!text-[32px] sm:leading-10"
      >
        {title}
      </Text>
      <Text
        spacingBottom="5"
        className="!text-[18px] !leading-[24px] sm:!text-[20px]"
      >
        {description}
      </Text>
      <Text
        className="!text-base !font-semibold leading-tight underline sm:!text-[17px] sm:leading-[21px]"
        color="primary"
        variant="caption2"
        asChild
      >
        <I13nAnchor
          href={ctaUrl}
          target="_blank"
          dataYlk={{
            elm: "hdln",
            itc: "0",
            slk: ctaText,
          }}
        >
          {ctaText}
        </I13nAnchor>
      </Text>
    </VStack>
  );
};

export default TextBlockListCard;
