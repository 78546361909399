import { beaconError } from "@yahoo-commerce/logger";
import { FetchError } from "@/lib/http";

export const postNewsletterSignUp = async ({
  formData,
}: {
  formData: FormData;
}): Promise<{ ok: boolean; status: number; success: boolean }> => {
  try {
    const response = await fetch("/api/newsletter/", {
      body: formData,
      method: "POST",
    });

    if (!response.ok) {
      throw new FetchError(response);
    }
    const data = await response.json();
    return { ok: response.ok, status: response.status, success: data.success };
  } catch (error) {
    beaconError("postNewsletterSignup", error as Error).catch((e) => {
      console.error("postNewsletterSignUp error: ", e);
    });

    return { ok: false, status: 500, success: false };
  }
};
