"use client";

import { useRapid, type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import Merchant from "@/components/expModules/MerchantsStackedGrid/Merchant";
import { type TopMerchant } from "@/types/Accio";

interface GridProps {
  dataYlk?: YlkData;
  id: string;
  merchants: TopMerchant[];
}

const SEC = "merchants-stacked-grid";

const Grid: FC<GridProps> = ({ dataYlk, id, merchants }) => {
  const { mpos } = useRapid(id, SEC);

  return (
    <div
      id={id}
      className="grid gap-2 px-4 pb-14 min-[640px]:grid-cols-2 min-[640px]:gap-4 min-[640px]:px-6 lg:grid-cols-3 lg:px-8"
    >
      {merchants.map((merchant, index) => (
        <Merchant
          key={merchant.unifiedMerchantId}
          merchant={merchant}
          dataYlk={{
            cpos: index + 1,
            itc: 0,
            mpos,
            sec: SEC,
            ...dataYlk,
          }}
        />
      ))}
    </div>
  );
};

export default Grid;
