import { Icon } from "@yahoo/uds";
import { ChevronRight } from "@yahoo/uds-icons";
import { I13nAnchor, type YlkData } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC } from "react";

interface PrimaryHeadingProps {
  dataYlk?: YlkData;
  heading: string;
  subtitle?: string;
  className?: string;
  url?: string;
}

const PrimaryHeading: FC<PrimaryHeadingProps> = ({
  dataYlk,
  heading,
  subtitle,
  className,
  url,
}) => {
  return (
    <div className={classNames("mb-4", className || "")}>
      <h2 className="flex items-center font-centra text-xl font-bold leading-[1.8125rem] text-primary md:text-[23px] md:font-[700] md:leading-7">
        {url ? (
          <I13nAnchor
            className="flex items-center gap-1.5 text-primary hover:underline"
            href={url}
            dataYlk={{
              elm: "link",
              slk: heading,
              ...dataYlk,
            }}
          >
            {heading}
            <Icon
              name={ChevronRight}
              size="sm"
              className="mt-1 text-uh-purple"
            />
          </I13nAnchor>
        ) : (
          heading
        )}
      </h2>
      {subtitle && (
        <h3 className="sub-headline-15px md:sub-headline-14px tracking-[0.3px] !text-battleship md:tracking-normal">
          {subtitle}
        </h3>
      )}
    </div>
  );
};

export default PrimaryHeading;
