"use client";

import { Icon } from "@yahoo/uds";
import { ChevronLeft, ChevronRight } from "@yahoo/uds-icons";
import { AriaCurrent, AriaRoles } from "@yahoo-commerce/a11y";
import { I13nAnchor, type YlkData } from "@yahoo-commerce/i13n";
import { type PageContext } from "@yahoo-commerce/util";
import { type FC } from "react";
import { useIntl } from "react-intl";
import "./Pagination.css";

interface PaginationProps {
  bookmarkId?: string; // If no Bookmark provided, just scroll to the top of the page
  dataYlk?: YlkData;
  itemsPerPage: number; // Number of items per page
  pageContext: PageContext; // Current active page
  totalItems: number; // Total number of items
}

export const Pagination: FC<PaginationProps> = ({
  bookmarkId,
  dataYlk,
  itemsPerPage,
  pageContext,
  totalItems,
}) => {
  const intl = useIntl();
  const { currentPage, basePath } = pageContext;
  const pageUrlFor = (pageNumber: number, withBasePath: string) => {
    let pageUrl;

    // Check for leading slash before setting pageUrl
    if (withBasePath.startsWith("/")) {
      pageUrl = withBasePath;
    } else {
      pageUrl = `/${withBasePath}`;
    }

    // Add trailing slash if needed
    if (!withBasePath.endsWith("/")) {
      pageUrl += "/";
    }
    if (pageNumber > 1) {
      pageUrl += `${pageNumber}/`;
    }
    if (bookmarkId) {
      pageUrl += `#${bookmarkId}`;
    }

    return pageUrl;
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const previousPage = currentPage > 1 ? currentPage - 1 : 1;
  const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;
  const previousPageUrl = pageUrlFor(previousPage, basePath);
  const nextPageUrl = pageUrlFor(nextPage, basePath);

  const getPages = () => {
    const pages: (number | string)[] = [];
    if (totalPages <= 1) return pages;

    // Always include the first page
    pages.push(1);

    // Add ellipsis after 9 when it is first page
    if (currentPage === 1) {
      const minPageNumber = Math.min(9, totalPages - 1);
      for (let i = 2; i <= Math.min(9, totalPages - 1); i++) {
        pages.push(i);
      }

      if (minPageNumber + 1 < totalPages) {
        pages.push("...");
      }
    } else {
      // Add ellipsis if needed
      if (currentPage > 4) {
        pages.push("...");
      }

      // Add pages around the current page
      for (
        let i = Math.max(2, currentPage - 2); // Start 2 pages before current page
        i <= Math.min(totalPages - 1, currentPage + 2); // End 2 pages after current page
        i++
      ) {
        pages.push(i);
      }

      // Add ellipsis if needed after the current page
      if (currentPage < totalPages - 3) {
        pages.push("...");
      }
    }

    // Always include the last page
    pages.push(totalPages);

    return pages;
  };

  const pages = getPages();
  return (
    <nav
      aria-label="pagination"
      className="mt-4 flex w-full max-w-full items-center justify-center space-x-1 overflow-x-auto"
    >
      <ul className="pagination-no-scrollbar m-0 flex list-none space-x-1 overflow-x-auto p-4">
        {currentPage > 1 && (
          <li key="button-prev">
            <I13nAnchor
              href={previousPageUrl}
              className="flex h-10 items-center rounded-xl px-4 py-2 text-inkwell hover:bg-pagination-hover focus:outline-pagination-focus focus-visible:outline-pagination-focus active:bg-pagination-pressed"
              dataYlk={{
                slk: "prev",
                ...dataYlk,
              }}
              aria-label={`${intl.formatMessage({
                id: "pagination.PREVIOUS",
              })} ${intl.formatMessage({
                id: "pagination.PAGE",
              })}`}
              role={AriaRoles.BUTTON}
            >
              <Icon
                name={ChevronLeft}
                variant="outline"
                size="md"
                color="primary"
              />{" "}
              {intl.formatMessage({ id: "pagination.PREV" })}
            </I13nAnchor>
          </li>
        )}

        {/* Page Numbers */}
        {pages.map((page) =>
          typeof page === "number" ? (
            <li key={`link-${page}`}>
              <I13nAnchor
                href={pageUrlFor(page, basePath)}
                className={`px-4 py-2 focus:outline-pagination-focus focus-visible:outline-pagination-focus ${
                  page === currentPage
                    ? "inline-flex size-10 min-h-[40px] min-w-[40px] items-center justify-center rounded-full bg-pagination-selected text-pagination-text-selected"
                    : `inline-flex size-10 min-h-[40px] min-w-[40px] items-center justify-center rounded-full text-pagination-text-default hover:bg-pagination-hover active:bg-pagination-pressed`
                }`}
                dataYlk={{
                  slk: page,
                  ...dataYlk,
                }}
                {...(page === currentPage
                  ? { "aria-current": AriaCurrent.PAGE }
                  : {})}
                aria-label={`${page} of ${totalPages} ${intl.formatMessage(
                  {
                    id: "pagination.PAGES",
                  },
                  { count: totalPages },
                )}`}
              >
                {page}
              </I13nAnchor>
            </li>
          ) : (
            <li key={`li-ellipsis`}>
              <span className="px-4 py-2 text-pagination-text-default">
                {page}
              </span>
            </li>
          ),
        )}

        {currentPage < totalPages && (
          <li key="button-next">
            <I13nAnchor
              href={nextPageUrl}
              className="flex h-10 items-center rounded-xl px-4 py-2 text-inkwell hover:bg-pagination-hover focus:outline-pagination-focus focus-visible:outline-pagination-focus active:bg-pagination-pressed"
              dataYlk={{
                slk: "next",
                ...dataYlk,
              }}
              aria-label={`${intl.formatMessage({
                id: "pagination.NEXT",
              })} ${intl.formatMessage({
                id: "pagination.PAGE",
              })}`}
              role={AriaRoles.BUTTON}
            >
              {intl.formatMessage({ id: "pagination.NEXT" })}
              <Icon
                name={ChevronRight}
                variant="outline"
                size="md"
                color="primary"
              />
            </I13nAnchor>
          </li>
        )}
      </ul>
    </nav>
  );
};
