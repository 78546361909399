import { type CarmotData } from "@yahoo-commerce/api";
import { type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import ThreeFeaturedCard from "./ThreeFeaturedCard";
import ThreeFeaturedHero from "./ThreeFeaturedHero";

interface ThreeFeaturedGridProps {
  stories: CarmotData[];
  dataYlk?: YlkData;
}

const CARD_COUNT = 3;

const ThreeFeaturedGrid: FC<ThreeFeaturedGridProps> = ({
  stories,
  dataYlk = {},
}) => {
  const [hero, ...restArticles] = stories;

  return (
    <section className="m-auto mt-8 grid w-full gap-6 px-4 sm:px-6 lg:grid-cols-[66%_auto] lg:px-6 xl:order-1 xl:mt-0 xl:grid-cols-[auto_65.8%] xl:p-0">
      {hero && (
        <ThreeFeaturedHero article={hero} dataYlk={{ cpos: 1, ...dataYlk }} />
      )}
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:h-fit">
        {restArticles.slice(0, CARD_COUNT).map((article, idx) => (
          <ThreeFeaturedCard
            article={article}
            key={idx}
            dataYlk={{ cpos: idx + 2, ...dataYlk }}
          />
        ))}
      </div>
    </section>
  );
};

export default ThreeFeaturedGrid;
