"use client";
import { type CarmotImage } from "@yahoo-commerce/api";
import {
  SCREEN_SIZES,
  SCREEN_WIDTH,
  type ScreenSize,
} from "@yahoo-commerce/util/lib/screens";
import { type ImageProps } from "next/dist/shared/lib/get-img-props";
import NextImage, { getImageProps } from "next/image";
import { useEffect, useState, type FC } from "react";
import {
  getImageByTag,
  mysterioImageSizes,
  type ImageTags,
} from "@/lib/contentGateway/util";

type Props = {
  alt?: string;
  className?: string;
  thumbnail?: CarmotImage;
  url?: string;
  priority?: boolean;
  size?: ImageTags;
};

type ThumbnailPicProps = Omit<Props, "size"> & {
  imageSizes: Record<ScreenSize, ImageTags>;
};

const fallbackSrc =
  "https://s.yimg.com/cv/apiv2/rocket/ImagePlaceholder16_9.png";

const getRequestedDimensions = (tag: ImageTags): [number, number] => {
  const size = mysterioImageSizes[tag];
  return size ? [size?.resizeFillW! || 0, size?.resizeFillH! || 0] : [0, 0];
};

const getImageDimension = ({
  thumbnail,
  size,
}: Pick<Props, "thumbnail" | "size">): {
  width: number;
  height: number;
  url?: string;
} => {
  let requestedWidth = 0,
    requestedHeight = 0;

  let resizedImage = getImageByTag({
    carmotMysterioImages: thumbnail?.carmotMysterioImages!,
    tag: size!,
  });

  if (!resizedImage) {
    // Use requested dimensions for placeholder
    [requestedWidth, requestedHeight] = getRequestedDimensions(size!);
  }

  const { height, width } = resizedImage || {
    height: requestedHeight,
    width: requestedWidth,
  };

  return {
    height: height!,
    url: resizedImage?.url,
    width: width!,
  };
};

const filterScreenSizes = (
  imageSizes: Record<ScreenSize, ImageTags>,
  sizes = SCREEN_SIZES,
) => {
  let i = 0,
    j = 1,
    res = [];
  while (j < sizes.length) {
    if (imageSizes[sizes[i]] === imageSizes[sizes[j]]) {
      j++;
    } else {
      res.push(sizes[i]);
      i = j++;
    }
  }
  res.push(sizes[i]);
  return res;
};

export const CarmotThumbnailPic: FC<ThumbnailPicProps> = ({
  alt = "",
  className,
  imageSizes,
  thumbnail,
  url,
  priority,
}) => {
  const common = {
    alt,
    className,
    onError: () => setError(true),
    priority,
    unoptimized: true,
  };
  let screenMap = new Map();
  filterScreenSizes(imageSizes)
    .toReversed()
    .map((screenSize) => {
      const size = imageSizes[screenSize as ScreenSize];
      screenMap.set(
        SCREEN_WIDTH[screenSize as ScreenSize],
        getImageDimension({ size, thumbnail }),
      );
    });

  const [error, setError] = useState(false);

  return (
    <picture>
      {Array.from(screenMap.entries()).map(
        ([screenSize, { width, height, url: resizedImageUrl = "" }], idx) => {
          if (idx === screenMap.size - 1) {
            const {
              props: { src, ...rest },
            } = getImageProps({
              ...common,
              height,
              src: resizedImageUrl || url,
              width,
            } as ImageProps);
            return (
              <NextImage
                key={idx}
                {...rest}
                loading={priority ? "eager" : "lazy"}
                src={error ? fallbackSrc : src || fallbackSrc}
                onError={() => setError(true)}
              />
            );
          }

          if (error) {
            return <></>;
          } else {
            return (
              <source
                key={screenSize}
                media={`(min-width: ${screenSize}px)`}
                srcSet={resizedImageUrl}
              />
            );
          }
        },
      )}
    </picture>
  );
};

const CarmotThumbnail: FC<Props> = ({
  alt = "",
  className,
  thumbnail,
  url,
  priority,
  size,
}) => {
  const {
    width,
    height,
    url: resizedImageUrl,
  } = getImageDimension({ size, thumbnail });

  // https://solutions-image-fallback.vercel.app/
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [resizedImageUrl]);

  const imageSrc = resizedImageUrl ?? url;

  return (
    <NextImage
      alt={alt}
      className={className}
      height={height!}
      onError={() => setError(true)}
      priority={priority}
      loading={priority ? "eager" : "lazy"}
      src={error ? fallbackSrc : imageSrc || fallbackSrc}
      unoptimized
      width={width!}
    />
  );
};

export default CarmotThumbnail;
