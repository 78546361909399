"use client";

import { HStack } from "@yahoo/uds";
import { AriaCurrent } from "@yahoo-commerce/a11y";
import { I13nAnchor, type I13NSec, useRapid } from "@yahoo-commerce/i13n";
import {
  debounce,
  useInView,
  getPathWithoutPage,
  getUrlObject,
} from "@yahoo-commerce/util";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import {
  type FC,
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavigationButton } from "@/components/expModules/common/carousel/Pagination";
import { useConfigContext } from "@/contexts/ConfigContext";
import { type SubNavConfig } from "@/types/Experience";

interface NavProps {
  subNavConfig: SubNavConfig[];
}

const SEC: I13NSec = "subnav";
const dataYlk = {
  elm: "navcat",
  itc: 0,
  subsec: "deal",
};

const usePaginationShown = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const { clientWidth, scrollWidth } = ref.current;
    setShow(scrollWidth > clientWidth);

    const observer = new ResizeObserver(
      debounce(([entry]) => {
        const { scrollWidth = 0 } = ref.current || {};
        const width = Math.ceil(
          entry.borderBoxSize?.[0]?.inlineSize ?? entry.contentRect.width,
        );

        setShow(scrollWidth > width);
      }, 300),
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return show;
};

const getActivePill = (subNavConfig: SubNavConfig[], pathname: string) => {
  return subNavConfig.findIndex(({ subNavURL }) => {
    const {
      meta: { url: baseUrlOverride },
    } = useConfigContext();
    const url = getUrlObject({ baseUrlOverride, url: subNavURL });
    return url?.pathname === pathname;
  });
};

const SubNavigation: FC<NavProps> = ({ subNavConfig = [] }) => {
  const pathname = usePathname();
  const activePillIdx = getActivePill(
    subNavConfig,
    getPathWithoutPage(pathname),
  );
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const showPagination = usePaginationShown(scrollRef);
  const firstPillRef = useRef<HTMLElement | null>(null);
  const lastPillRef = useRef<HTMLElement | null>(null);
  const firstPillInView = useInView(firstPillRef, { threshold: 0.8 });
  const lastPillInView = useInView(lastPillRef, { threshold: 0.8 });
  useRapid(SEC);

  if (!subNavConfig.length) return null;

  const onScroll =
    (direction = "next") =>
    () => {
      if (scrollRef.current) {
        const { scrollLeft, clientWidth } = scrollRef.current;
        const totalPadding = 116; // 16px padding + 42px nav button width on both sides
        scrollRef.current.scrollTo({
          behavior: "smooth",
          left:
            direction === "next"
              ? scrollLeft + clientWidth - totalPadding
              : scrollLeft - clientWidth + totalPadding,
        });
      }
    };

  return (
    <nav className="relative" id={SEC}>
      {showPagination && !firstPillInView && (
        <div className="absolute left-0 z-10 h-11 w-[66px] bg-gradient-to-l from-transparent via-white via-[53.61%] to-white">
          <NavigationButton
            arrow="prev"
            className="float-left"
            handleClick={onScroll("prev")}
          />
        </div>
      )}
      {showPagination && !lastPillInView && (
        <div className="absolute right-0 z-10 h-11 w-[66px] bg-gradient-to-l from-white via-white via-[56%] to-transparent">
          <NavigationButton
            arrow="next"
            className="float-right"
            handleClick={onScroll("next")}
          />
        </div>
      )}
      <HStack
        ref={scrollRef}
        alignItems="center"
        justifyContent="center"
        gap="2"
        className={classNames(
          "no-scrollbar flex snap-x snap-mandatory flex-nowrap overflow-x-scroll py-0.5",
          showPagination && "justify-start",
        )}
      >
        {subNavConfig.map(({ subNavURL: url, subNavLabel: title }, idx) => {
          return (
            <I13nAnchor
              key={idx}
              href={url}
              className={`whitespace-nowrap rounded-full border px-5 py-[10px] text-sm font-semibold transition-transform duration-[220ms] hover:scale-[1.03] active:scale-[0.97] ${
                idx !== activePillIdx
                  ? "border-dirty-seagull text-inkwell hover:bg-marshmallow active:bg-pebble active:text-charcoal"
                  : "bg-spectrum-color-purple-9 text-white hover:bg-spectrum-color-purple-10 active:bg-spectrum-color-purple-11"
              }`}
              dataYlk={{ ...dataYlk, slk: title }}
              {...(idx === activePillIdx
                ? { "aria-current": AriaCurrent.PAGE }
                : {})}
            >
              <span
                ref={
                  (idx === 0 && firstPillRef) ||
                  (idx === subNavConfig.length - 1 && lastPillRef) ||
                  null
                }
              >
                {title}
              </span>
            </I13nAnchor>
          );
        })}
      </HStack>
    </nav>
  );
};

export default SubNavigation;
